<template>
  <div class="student-buy">
    <div class="title">是否确认给该学员加入会员服务？</div>
    <el-table border ref="multipleTable" :data="tableData" @selection-change="tableSelectHandler">
      <el-table-column type="selection" width="55" :selectable="selectEnable"></el-table-column>
      <el-table-column label="产品名称" prop="rowName"></el-table-column>
      <el-table-column label="产品价格" prop="rowPrice"></el-table-column>
    </el-table>
    <div class="title">
      确认后将从{{ $store.state.user.drvSchoolForShort }}余额中扣除<span style="color: #ff422d">{{ totalPrice }}元</span
      >会员服务费用
    </div>
    <div class="admin-dialog-btns">
      <el-button type="primary" @click="submitHandler()">确认</el-button>
      <el-button @click="$emit('end')">取消</el-button>
    </div>
  </div>
</template>

<script>
import {schStudentBuy} from '@/api/index';
import Decimal from 'decimal.js';
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      loading: false,
      tableData: [
        // {templateType: 1, rowName: '会员+补考费服务', rowPrice: ''},
      ],
      selectedData: [],
      totalPrice: 0,
    };
  },
  created() {
    this.loading = true;
    var templatePrice1 = 0;
    // var templatePrice2 = 0;
    this.$store.state.template.templateData.forEach((item) => {
      if (item.templateType == 1) {
        const obj = JSON.parse(item.templateCode);
        obj.sub1.forEach((product1) => {
          if (product1.vehicleType == this.$props.row.usableVehicleType) {
            templatePrice1 = Decimal(templatePrice1).add(product1.income).toFixed(0);
          }
        });
        obj.sub2.forEach((product2) => {
          if (product2.vehicleType == this.$props.row.usableVehicleType) {
            templatePrice1 = Decimal(templatePrice1).add(product2.income).toFixed(0);
          }
        });
        obj.sub3.forEach((product3) => {
          if (product3.vehicleType == this.$props.row.usableVehicleType) {
            templatePrice1 = Decimal(templatePrice1).add(product3.income).toFixed(0);
          }
        });
      }
    });

    if (
      this.$store.state.template.templateType == 1 &&
      (!this.$props.row.resitAuditStatus || this.$props.row.resitAuditStatus == 30)
    ) {
      this.tableData.push({templateType: 1, rowName: '会员+补考费服务', rowPrice: templatePrice1});
      this.$nextTick(() => {
        this.$refs.multipleTable.toggleRowSelection(this.tableData[0], true);
      });
    } else if (this.$store.state.template.templateType == 3) {
      if (!this.$props.row.resitAuditStatus || this.$props.row.resitAuditStatus == 30) {
        this.tableData.push({templateType: 1, rowName: '会员+补考费服务', rowPrice: templatePrice1});
        this.$nextTick(() => {
          this.$refs.multipleTable.toggleRowSelection(this.tableData[0], true);
        });
      }
    }
    this.loading = false;
  },
  methods: {
    tableSelectHandler(val) {
      this.totalPrice = 0;
      val.forEach((item) => {
        this.totalPrice = Decimal(this.totalPrice).add(item.rowPrice).toFixed(0);
      });
      this.selectedData = val;
    },

    // 补考费服务不可变动，默认选中
    selectEnable(row) {
      if (row.templateType == 1) {
        return false;
      } else {
        return true;
      }
    },

    submitHandler() {
      if (!this.selectedData.length) {
        this.$message.warning('请选中需要购买的会员服务！');
        return false;
      }
      this.loading = true;
      let postData = {
        drvStudentId: this.$props.row.drvStudentId,
        policyContractType: 1,
      };
      this.$utils.resolveRequest(schStudentBuy(postData), this);
    },
  },
};
</script>

<style lang="scss">
.student-buy {
  .title {
    font-size: 18px;
    margin: 20px 0;
  }
}
</style>